import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import { PageFooter } from "@components/page-footer";
var timer;
export default {
  data: function data() {
    return {
      loginPage: true,
      isShow: false,
      accountNum: null,
      verifyCode: null,
      phone: '',
      activeIndex: 1,
      time: 90,
      showTime: false,
      showErr: false,
      errMsg: '',
      checked: false
    };
  },
  components: {
    PageFooter: PageFooter
  },
  //离开之前清除缓存
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    localStorage.removeItem('stopTime');
    next();
  },
  mounted: function mounted() {
    if (localStorage.getItem('stopTime')) {
      if (this.getCount() > 0 && this.getCount() < 90) this.showTime = true;
      this.getMsg();
    }
  },
  methods: {
    //返回首页
    toIndex: function toIndex() {
      this.$router.push({
        path: "/"
      });
    },
    // 头部登录
    toLogin: function toLogin() {
      this.$router.push({
        path: "/account/login"
      });
    },
    inputChange: function inputChange(e) {
      if (!this.verifyCode) {
        this.errMsg = '请输入验证码！';
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    //验证码
    inputBlur: function inputBlur(e) {
      if (!this.verifyCode) {
        this.errMsg = '请输入验证码！';
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    phoneBlur: function phoneBlur() {
      //验证手机号
      var res = /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/;
      if (!res.test(this.phone)) {
        this.showErr = true;
        return;
      } else {
        this.showErr = false;
      }
    },
    // 注册并登录
    registLogin: function registLogin() {
      var _this = this;
      if (!this.phone) {
        this.showErr = true;
        return;
      }
      this.phoneBlur();
      if (!this.verifyCode) {
        this.isShow = true;
        this.errMsg = '请输入验证码！';
        return;
      }
      var url = '/pjyy-deepexi-user-center/api/v1/common/registerAccount';
      var params = {
        loginSource: 'PC',
        phone: this.phone,
        verifyCode: this.verifyCode
      };
      this.$getHttpClient().post(url, params).then(function (res) {
        if (res.data.code == '200') {
          _this.$buefy.toast.open({
            message: "注册成功",
            type: "is-success"
          });
          _this.$router.push({
            path: "/account/login"
          });
        }
      });
    },
    getverifyCode: function getverifyCode() {
      var _this2 = this;
      var url = "/pjyy-deepexi-user-center/api/v1/common/registerAccount/sendVerifyCode?phone=".concat(this.phone);
      this.$getHttpClient().post(url).then(function (res) {
        if (res.data.code == '200') {
          localStorage.setItem('stopTime', new Date().getTime() + 90 * 1000);
          _this2.getMsg();
        }
      });
    },
    //获取验证码倒计时
    getMsg: function getMsg() {
      var _this3 = this;
      if (timer) {
        clearInterval(timer);
      }
      var that = this;
      this.time = this.getCount();
      //设置button效果，开始计时
      timer = setInterval(function () {
        if (that.time <= 0) {
          clearInterval(timer); //停止计时器
          that.showTime = false;
        } else {
          _this3.showTime = true;
          that.time--;
        }
      }, 1000);
    },
    //通过本地缓存获取倒计时
    getCount: function getCount() {
      var now = new Date().getTime();
      if (localStorage.getItem('stopTime')) {
        var stopTime = localStorage.getItem('stopTime');
        var remainTime = parseInt((stopTime - now) / 1000);
        //设定的倒计时，
        if (remainTime > 0) {
          //剩下多少秒
          return remainTime;
        } else {
          localStorage.setItem('stopTime', 0);
          return 0;
        }
      } else {
        localStorage.setItem('stopTime', new Date().getTime() + 90 * 1000);
        return 90;
      }
    }
  }
};