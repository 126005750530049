import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-register",
    staticStyle: {
      "background": "#fff"
    }
  }, [_c('div', {
    staticClass: "header-wrap"
  }, [_c('div', {
    staticClass: "header-cont-wrap"
  }, [_c('div', [_c('img', {
    staticClass: "logo-img",
    attrs: {
      "src": require("@images/logo.png"),
      "alt": ""
    },
    on: {
      "click": _vm.toIndex
    }
  })]), _c('div', [_vm._v(" 已有普健商城账号？ "), _c('input', {
    staticClass: "header-right-btn toLogin",
    attrs: {
      "type": "button",
      "value": "登录"
    },
    on: {
      "click": _vm.toLogin
    }
  }), _c('input', {
    staticClass: "header-right-btn",
    attrs: {
      "type": "button",
      "value": "返回首页"
    },
    on: {
      "click": _vm.toIndex
    }
  })])])]), _c('div', {
    staticClass: "reset-main"
  }, [_c('div', {
    staticClass: "main-wrap"
  }, [_c('div', {
    staticClass: "accountNum"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone,
      expression: "phone"
    }],
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": "请输入手机号",
      "maxlength": "11"
    },
    domProps: {
      "value": _vm.phone
    },
    on: {
      "blur": _vm.phoneBlur,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.phone = $event.target.value;
      }
    }
  }), _vm.showErr ? _c('span', {
    staticClass: "msgColor"
  }, [_vm._v("请输入正确的手机号码！")]) : _vm._e()]), _c('div', {
    staticClass: "top"
  }, [_vm._m(1), _c('div', {
    staticClass: "right-msg"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.verifyCode,
      expression: "verifyCode"
    }],
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": "请输入验证码"
    },
    domProps: {
      "value": _vm.verifyCode
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.verifyCode = $event.target.value;
      }, _vm.inputChange],
      "blur": _vm.inputBlur
    }
  }), !_vm.showTime ? _c('span', {
    staticClass: "getMsg",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.getverifyCode();
      }
    }
  }, [_vm._v("获取验证码")]) : _vm._e(), _vm.showTime ? _c('span', {
    staticClass: "getMsg showTime",
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(_vm._s(_vm.time) + "s")]) : _vm._e()]), _vm.isShow ? _c('span', {
    staticClass: "msgColor"
  }, [_vm._v(_vm._s(_vm.errMsg))]) : _vm._e()])]), _c('button', {
    class: ['nextTic', {
      'disbtn': !_vm.phone || !_vm.verifyCode || !_vm.checked
    }],
    attrs: {
      "disabled": !_vm.phone || !_vm.verifyCode || !_vm.checked
    },
    on: {
      "click": function click($event) {
        return _vm.registLogin();
      }
    }
  }, [_vm._v("注册并进行登录")]), _c('div', {
    staticClass: "read"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    attrs: {
      "type": "checkbox",
      "name": "",
      "id": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, null) > -1 : _vm.checked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.checked,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.checked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checked = $$c;
        }
      }
    }
  }), _vm._v("阅读并同意"), _c('a', {
    attrs: {
      "href": "/register-report.html",
      "target": "_blank"
    }
  }, [_vm._v("《用户注册协议》")])])])]), _c('PageFooter', {
    attrs: {
      "loginPage": _vm.loginPage
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "accountInp"
  }, [_c('span', {
    staticClass: "msgColor"
  }, [_vm._v("*")]), _vm._v("手机号码：")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "accountInp"
  }, [_c('span', {
    staticClass: "msgColor"
  }, [_vm._v("*")]), _vm._v("短信验证码：")]);
}];
export { render, staticRenderFns };